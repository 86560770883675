/* eslint-disable react/jsx-curly-newline */
import { Button, Col, Divider, Row, Select } from 'antd';
import { TableWrapper } from 'components/TableWrapper';
import { ROUTES } from 'helpers/constants/routes';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { debounce } from 'lodash';
import { Title } from 'pages/App/Layout.sty';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useProductContent from './hooks/useProductContent';
import { ButtonCol, SearchInput } from './ProductContent.sty';
import { ProductTable } from './ProductTable';
import { getRevisionStatuses } from './utils/revisionStatus';

export const ProductContent = () => {
  const navigate = useNavigate();
  const {
    fetchNextPage,
    productList,
    isLoading,
    hasNextPage,
    setProductSearchKey,
    setRevisionStatusFilter,
    setStatusFilter,
  } = useProductContent();
  const { t } = useTranslation();
  const { AccessWrapper } = useRoleAccess('product.editActions');

  const onGoToUpdate = (urlName: string) => {
    navigate(`${ROUTES.updateProduct}/${urlName}`);
  };

  const handleSearchWithDebounce = useMemo(
    () =>
      debounce((_val: string) => {
        setProductSearchKey(_val);
      }, 700),
    [setProductSearchKey],
  );

  const goToCreateProduct = () => {
    navigate(`${ROUTES.createProduct}`);
  };

  return (
    <>
      <Title level={1}>{t('productList.title')}</Title>
      <Divider />
      <Row gutter={12}>
        <Col xl={17} lg={16} md={14} xs={24}>
          <SearchInput
            placeholder={t('textSearchPlaceholder')}
            onChange={({
              target: { value },
            }: React.ChangeEvent<HTMLInputElement>) =>
              handleSearchWithDebounce(value)
            }
          />
          <Select
            onSelect={setRevisionStatusFilter}
            placeholder={t('productList.filters.revisionStatus')}
            allowClear
            onClear={() => setRevisionStatusFilter(undefined)}
            options={getRevisionStatuses(t)}
          />
        </Col>
        <AccessWrapper>
          <ButtonCol xl={7} lg={8} md={10} xs={24}>
            <Row gutter={2}>
              <Col span={12}>
                <Button type="primary" onClick={goToCreateProduct}>
                  {t('productForm.createProduct')}
                </Button>
              </Col>
            </Row>
          </ButtonCol>
        </AccessWrapper>
      </Row>
      <Divider />
      <TableWrapper>
        <ProductTable
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          data={productList}
          onGoToUpdate={onGoToUpdate}
          setStatusFilter={setStatusFilter}
        />
      </TableWrapper>
    </>
  );
};
