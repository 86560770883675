/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
import { Spin, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import InfiniteScrollWrapper from 'components/InfiniteScrollWrapper';
import { t } from 'i18next';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { VariantStatus } from 'services/product/interface';
import useProductTable from './hooks/useProductTable';
import { ProductContent, ProductTableProps } from './interface';
import { InfiniteTableLoader } from './ProductTable.sty';

export const ProductTable = ({
  data,
  isLoading,
  fetchNextPage,
  onGoToUpdate,
  hasNextPage,
  setStatusFilter,
}: ProductTableProps) => {
  const { columns } = useProductTable();
  const dataSource: ProductContent[] = useMemo(
    () =>
      data.map((product) => ({
        ...product,
        key: product.id,
      })),
    [data],
  );

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ProductContent> | SorterResult<ProductContent>[],) => {
    if (!setStatusFilter) return;

    const variantFilterValue = filters.status?.[0];
    if (variantFilterValue) {
      setStatusFilter(variantFilterValue as VariantStatus);
    } else {
      setStatusFilter(undefined);
    }
  };

  return (
    <InfiniteScrollWrapper id="scrollableDiv">
      <InfiniteScroll
        next={fetchNextPage}
        dataLength={dataSource.length}
        hasMore={hasNextPage ?? false}
        scrollableTarget="scrollableDiv"
        loader={
          <InfiniteTableLoader>
            <Spin tip={t('loading')} />
          </InfiniteTableLoader>
        }
      >
        <Table
          loading={isLoading}
          onRow={(record: ProductContent) => ({
            onClick: () => onGoToUpdate(record.urlName),
          })}
          onChange={handleTableChange}
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          key={1}
        />
      </InfiniteScroll>
    </InfiniteScrollWrapper>
  );
};
