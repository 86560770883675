import { VariantFormValues } from 'components/VariantForm/interface';
import { ProductQueryParams } from 'helpers/hooks/useModuleCRUD/interface/useModuleCRUD';
import { getAPIMethod } from 'helpers/libs/Http';
import { Product, RevisionStatus } from './interface';

const getProductList = async (queryParams?: ProductQueryParams) => {
  const response = await getAPIMethod({
    name: 'getProductList',
    params: {
      search: queryParams?.search,
      page: queryParams?.page,
      pageSize: queryParams?.pageSize,
      revisionStatus: queryParams?.revisionStatus,
      status: queryParams?.status,
    },
  });
  return response.data;
};

const getProductByUrlName = async (productUrlName: string) => {
  const response = await getAPIMethod({
    name: 'getByProductByName',
    pathParams: { name: productUrlName },
  });
  return response.data;
};

const createProduct = async (product: Product) => {
  const response = await getAPIMethod({
    name: 'createProduct',
    data: { ...product, revisionStatus: RevisionStatus.APPROVED },
  });
  return response.data;
};

const updateProductById = async (product: Product) => {
  const response = await getAPIMethod({
    name: 'updateProductById',
    pathParams: { productId: product.id },
    data: product,
  });
  return response.data;
};

const deleteProductById = async (id: string) => {
  const response = await getAPIMethod({
    name: 'deleteProductById',
    pathParams: { productId: id },
  });
  return response;
};

export const variantTypeParser = (
  data: VariantFormValues,
): VariantFormValues => ({
  ...data,
  price: Number(data.price ?? 0),
  cost: Number(data.cost ?? 0),
  discount: Number(data.discount ?? 0),
});

export default {
  create: createProduct,
  read: getProductList,
  update: updateProductById,
  delete: deleteProductById,
  readByParam: getProductByUrlName,
};
