/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
import { Space, Tag } from 'antd';
import { LightTheme } from 'helpers/constants/theme';
import { useMemo } from 'react';
import { useMediaQuery } from 'helpers/hooks/useMediaQuery/useMediaQuery';
import { VariantStatusColor } from 'containers/Products/VariantList/interface';
import { t } from 'i18next';
import ThumbnailRender from 'containers/Products/VariantList/components/ThumbnailRender';
import { Variant, VariantStatus } from 'services/product/interface';
import { ProductContent } from '../interface';
import { ThumbnailImageWrapper } from '../ProductTable.sty';

const tableColumns = (
  tagRenderer: (tags: string[] | undefined) => JSX.Element | null,
  statusRenderer: (status: VariantStatus) => JSX.Element | null,
) => [
  {
    title: t('productTableKeys.image'),
    render: (product: ProductContent) => (
      <ThumbnailRender variant={product.variants?.[0] as Variant} />
    ),
  },
  {
    title: t('productTableKeys.name'),
    dataIndex: 'name',
    width: '350px',
  },
  {
    title: t('productTableKeys.category'),
    dataIndex: 'parentCategoryName',
  },
  {
    title: t('productTableKeys.subcategory'),
    dataIndex: 'categoryName',
  },
  {
    title: t('productTableKeys.variantNumber'),
    render: (product: ProductContent) => product.variants?.length || 0,
  },
  {
    title: t('productTableKeys.status'),
    key: 'status',
    filters: [
      {
        text: t('productList.variantStatus.ACTIVE'),
        value: VariantStatus.ACTIVE,
      },
      {
        text: t('productList.variantStatus.INACTIVE'),
        value: VariantStatus.INACTIVE,
      },
      {
        text: t(`productList.variantStatus.ALL`),
        value: 'ALL',
      },
    ],
    defaultFilteredValue:[VariantStatus.ACTIVE],
    filterMultiple: false,
    render: (product: ProductContent) =>
      product.variants?.some(
        (variant) => variant.status === VariantStatus.ACTIVE,
      )
        ? statusRenderer(VariantStatus.ACTIVE)
        : statusRenderer(VariantStatus.INACTIVE),
  },
  {
    title: t('productTableKeys.tags'),
    render: (product: ProductContent) =>
      tagRenderer(
        product.variants?.flatMap((variant) => variant.tags ?? []).slice(0, 2),
      ),
  },
  {
    title: t('productTableKeys.brand'),
    render: (product: ProductContent) => (
      <ThumbnailImageWrapper width="70px">
        <img
          src={
            product.brand?.logo?.url ?? product.variants?.[0]?.brand?.logo?.url
          }
          alt={
            product.brand?.logo?.name ??
            product.variants?.[0]?.brand?.logo?.name
          }
        />
      </ThumbnailImageWrapper>
    ),
  },
];

const statusRenderer = (status: VariantStatus) => (
  <Tag color={VariantStatusColor[status]}>
    {t(`variantForm.labels.status.${status.toLowerCase()}`)}
  </Tag>
);
const tagRenderer = (tags: string[] | undefined) => (
  <Space direction="vertical">
    {(tags ?? []).map((tag) => (
      <Tag key={tag} color="yellow">
        {tag || 'SIN TAG'}
      </Tag>
    ))}
  </Space>
);

const useProductTable = () => {
  const defaultColumns = tableColumns(tagRenderer, statusRenderer);
  const isMobile = useMediaQuery(`(${LightTheme.queries.xs})`);
  const columns = useMemo(() => {
    const mobileHidableColumns: string[] = [
      t('productTableKeys.variantNumber'),
      t('productTableKeys.tags'),
    ];
    const filteredColumns = isMobile
      ? defaultColumns.filter(
          (column) => !mobileHidableColumns.includes(column.title),
        )
      : defaultColumns;
    return filteredColumns;
  }, [defaultColumns, isMobile]);

  return {
    columns,
  };
};

export default useProductTable;
